import MikeB from '../../imgs/employees/mike-barnes.jpg'
import MikeyB from '../../imgs/employees/mikey-barnes.jpg'
import MikeS from '../../imgs/employees/mike-simmons.jpg'
import ChinmoyB from '../../imgs/employees/chinmoy-bose.jpg'
import PatW from '../../imgs/employees/pat-walter.jpg'
import MartinV from '../../imgs/employees/martin-vivenzi.jpg'
import ManuelL from '../../imgs/employees/manuel-lara.jpg'
import JasonR from '../../imgs/employees/jason-roberson.jpg'
import MatthewB from '../../imgs/employees/matthew-borunda.jpg'
import MattD from '../../imgs/employees/matt-dubbs.jpg'
import DaveD from '../../imgs/employees/dave-davison.jpg'
import ScottC from '../../imgs/employees/scott-campbell.jpg'
import KenH from '../../imgs/employees/ken-howe.jpg'
import PeteE from '../../imgs/employees/pete-elizalde.jpg'
import JeffS from '../../imgs/employees/jeff-schwitzer.jpg'
import JohnB from '../../imgs/employees/john-ball.jpg'
import SergioS from '../../imgs/employees/sergio-sevilla.JPG'
import TrinidadO from '../../imgs/employees/trinidad-ortiz.jpg'
import mBradley from '../../imgs/employees/mike-bradley.jpg'
import JasonD from '../../imgs/employees/jason-dufton.jpg'
import PhilT from '../../imgs/employees/phil-tapia.jpg'
import ScottS from '../../imgs/employees/scott-salinas.jpg'
import SeanS from '../../imgs/employees/sean-silva.jpg'
import GeorgeL from '../../imgs/employees/george-larios.jpg'
import JohnC from '../../imgs/employees/john-charles.jpg'
import StephenL from '../../imgs/employees/stephen-lambarena.jpg'
import IsaacH from '../../imgs/employees/isaac-hernandez.jpg'
import JoshB from '../../imgs/employees/josh-bradley.jpg'
import ShawnP from '../../imgs/employees/shawn-paz.jpg'
import DonavenM from '../../imgs/employees/donaven-murphy.jpg'
import GilbertF from '../../imgs/employees/gilberto-figueroa.jpg'
import SamG from '../../imgs/employees/sam-gonzalez.jpg'
import DerrenE from '../../imgs/employees/derren-edenstrom.jpg'
import DeshanP from '../../imgs/employees/deshan-pather.jpg'
import JacobE from '../../imgs/employees/jacob-edmunds.jpg'
import ChristopherF from '../../imgs/employees/christopher-ficke.jpg'
import DarinM from '../../imgs/employees/darin-morales.jpg'
import JeffD from '../../imgs/employees/jeff-darneal.jpg'
import JeffY from '../../imgs/employees/jeff-yesne.jpg'
import TimB from '../../imgs/employees/tim-buffuna.jpg'
import RandyK from '../../imgs/employees/randy-keeler.jpg'
import CoreyA from '../../imgs/employees/corey-aivazian.jpg'
import filler1 from '../../imgs/employees/silhouette-employee-avatar.jpg'

const EmployeeDirectoryData = [
  {
    img: MikeB,
    name: 'Mike Barnes',
    title: 'President / CEO',
    phone: '(559) 233-6684',
    email: 'mikeb@barneswelding.com',
    location: 'Fresno Corporate Office',
  },
  {
    img: MikeyB,
    name: 'Mikey Barnes',
    title: 'Vice President',
    phone: '(559) 233-6684',
    email: 'mikeyb@barneswelding.com',
    location: 'Fresno Corporate Office',
  },
  {
    img: JeffD,
    name: 'Jeff Darneal',
    title: 'Director of Human Resources',
    phone: '(559) 233-6684',
    email: 'jeffd@barneswelding.com',
    location: 'Fresno Corporate Office',
  },
  {
    img: PatW,
    name: 'Pat Walter',
    title: 'Process Specialist',
    phone: '(559) 233-6684',
    email: 'patw@barneswelding.com',
    location: 'Fresno Corporate Office',
  },
  {
    img: MikeS,
    name: 'Mike Simmons',
    title: 'Purchasing Manager',
    phone: '(559) 233-6684',
    email: 'mikes@barneswelding.com',
    location: 'Fresno Corporate Office',
  },
  {
    img: SamG,
    name: 'Samuel Gonzalez',
    title: 'Director Of Finance / Operations ',
    phone: '(559) 233-6684',
    email: 'samg@barneswelding.com',
    location: 'Fresno Corporate Office',
  },
  {
    img: ChinmoyB,
    name: 'Chinmoy Bose',
    title: 'Director of Specialty Gases & Bulk Gas Coordinator',
    phone: '(559) 233-6684',
    email: 'chinmoyb@barneswelding.com',
    location: 'Fresno Corporate Office',
  },
  {
    img: DarinM,
    name: 'Darin Morales',
    title: 'TapsCO2 Sales Manager',
    phone: '(559) 233-6684',
    email: 'darinm@barneswelding.com',
    location: 'Fresno Corporate Office',
  },
  {
    img: JohnB,
    name: 'John Ball',
    title: 'Dry Ice / Bulk Gas Sales',
    phone: '(559) 267-3279',
    email: 'johnb@barneswelding.com',
    location: 'Fresno Corporate Office',
  },
  {
    img: PhilT,
    name: 'Phil Tapia',
    title: 'Operations / Compliance Manager',
    phone: '(559) 233-6684',
    email: 'philt@barneswelding.com',
    location: 'Fresno Corporate Office',
  },
  {
    img: CoreyA,
    name: 'Corey Aivazian',
    title: 'Sales Manager',
    phone: '(559) 233-6684',
    email: 'coreya@barneswelding.com',
    location: 'Fresno Corporate Office',
  },
  {
    img: MartinV,
    name: 'Martin Vivenzi',
    title: 'Project Manager',
    phone: '(559) 233-6684',
    email: 'martinv@barneswelding.com',
    location: 'Fresno Corporate Office',
  },
  {
    img: DonavenM,
    name: 'Donaven Murphy',
    title: 'Branch Operations Manager',
    phone: '(559) 233-6684',
    email: 'donavenm@barneswelding.com',
    location: 'Fresno Corporate Office',
  },
  {
    img: SergioS,
    name: 'Sergio Sevilla',
    title: 'Visalia Store Manager',
    phone: '(559) 733-2335',
    email: 'sergios@barneswelding.com',
    location: 'Visalia Store',
  },
  {
    img: JasonR,
    name: 'Jason Roberson',
    title: 'Porterville Store Manager',
    phone: '(559) 782-1025',
    email: 'jasonr@barneswelding.com',
    location: 'Porterville Store',
  },
  {
    img: JeffY,
    name: 'Jeff Yesne',
    title: 'Fresno Store Manager',
    phone: '(559) 233-9353',
    email: 'jeffy@barneswelding.com',
    location: 'Fresno Store',
  },
  {
    img: MatthewB,
    name: 'Matthew Borunda',
    title: 'Clovis Store Manager',
    phone: '(559) 292-1234',
    email: 'mborunda@barneswelding.com',
    location: 'Clovis Store',
  },
  {
    img: TrinidadO,
    name: 'Trinidad Ortiz',
    title: 'Madera Store Manager',
    phone: '(559) 718-2072',
    email: 'trinidado@barneswelding.com',
    location: 'Madera Store',
  },
  {
    img: ChristopherF,
    name: 'Christopher Ficke',
    title: 'Citrus Heights Store Manager',
    phone: '(916) 718-2072',
    email: 'christopherf@barneswelding.com',
    location: 'Citrus Heights',
  },
  {
    img: mBradley,
    name: 'Mike Bradley',
    title: 'Bakersfield Store Manager',
    phone: '(661) 322-9353',
    email: 'mbradley@barneswelding.com',
    location: 'Bakersfield Store',
  },
  {
    img: filler1,
    name: 'Richard Reid',
    title: 'Sacramento Store Manager',
    phone: '(916) 456-2478',
    email: 'richardr@barneswelding.com',
    location: 'Sacramento Store',
  },
  {
    img: DerrenE,
    name: 'Derren Edenstrom',
    title: 'West Sacramento Store Manager',
    phone: 'Coming Soon',
    email: 'derrene@barneswelding.com',
    location: 'West Sacramento Store',
  },
  {
    img: MattD,
    name: 'Matt Dubbs',
    title: 'Modesto Store Manager',
    phone: '(209) 491-2780',
    email: 'mattd@barneswelding.com',
    location: 'Modesto Store',
  },
  {
    img: filler1,
    name: 'Chris McDiffett',
    title: 'Turlock Store Manager',
    phone: '(209) 216-5592',
    email: 'chrism@barneswelding.com',
    location: 'Turlock Store',
  },
  {
    img: ManuelL,
    name: 'Manuel Lara',
    title: 'Salinas Store Manager',
    phone: '(831) 783-1806',
    email: 'manuell@barneswelding.com',
    location: 'Salinas Store',
  },
  {
    img: GilbertF,
    name: 'Gilberto Figueroa',
    title: 'Stockton Store Manager',
    phone: '(209) 463-9353',
    email: 'gilbertof@barneswelding.com',
    location: 'Stockton Store',
  },
  {
    img: RandyK,
    name: 'Randy Keeler',
    title: 'Fresno Account Manager',
    phone: '(559) 240-3770',
    email: 'randallk@barneswelding.com',
    location: 'Fresno',
  },
  {
    img: GeorgeL,
    name: 'George Larios',
    title: 'Fresno Account Manager',
    phone: '(559) 288-7856',
    email: 'georgel@barneswelding.com',
    location: 'Fresno',
  },
  {
    img: ScottS,
    name: 'Scott Salinas',
    title: 'Fresno Account Manager',
    phone: '(559) 905-1277',
    email: 'scotts@barneswelding.com',
    location: 'Fresno',
  },
  {
    img: SeanS,
    name: 'Sean Silva',
    title: 'Visalia Account Manager',
    phone: '(559) 905-1281',
    email: 'seans@barneswelding.com',
    location: 'Visalia',
  },
  {
    img: StephenL,
    name: 'Stephen Lambarena',
    title: 'Visalia Account Manager',
    phone: '(559) 905-1284',
    email: 'stephenl@barneswelding.com',
    location: 'Visalia',
  },
  {
    img: JoshB,
    name: 'Josh Bradley',
    title: 'Bakersfield Account Manager',
    phone: '(661) 342-8770',
    email: 'joshb@barneswelding.com',
    location: 'Bakersfield',
  },
  {
    img: IsaacH,
    name: 'Isaac Hernandez',
    title: 'Bakersfield Account Manager',
    phone: '(661) 301-5050',
    email: 'isaach@barneswelding.com',
    location: 'Bakersfield',
  },
  {
    img: DaveD,
    name: 'Dave Davison',
    title: 'Sacramento Account Manager',
    phone: '(916) 292-1217',
    email: 'daved@barneswelding.com',
    location: 'Sacramento',
  },
  {
    img: JohnC,
    name: 'John Charles',
    title: 'Sacramento Account Manager',
    phone: '(916) 203-9639',
    email: 'johnc@barneswelding.com',
    location: 'Sacramento',
  },
  {
    img: ShawnP,
    name: 'Shawn Paz',
    title: 'Sacramento Account Manager',
    phone: '(916) 769-5320',
    email: 'shawnp@barneswelding.com',
    location: 'Sacramento',
  },
  {
    img: TimB,
    name: 'Tim Buffuna',
    title: 'TapsCO2 Sacramento Account Manager',
    phone: '(916) 206-8610',
    email: 'timb@tapsco2.com',
    location: 'Sacramento',
  },
  {
    img: JacobE,
    name: 'Jacob Edmunds',
    title: 'Sacramento Account Manager',
    phone: '(916) 456-2478',
    email: 'jacobe@barneswelding.com',
    location: 'Sacramento',
  },
  {
    img: DeshanP,
    name: 'Deshan Pather',
    title: 'West Sacramento Account Manager',
    phone: '(916) 917-6805',
    email: 'deshanp@barneswelding.com',
    location: 'West Sacramento',
  },
  {
    img: JasonD,
    name: 'Jason Dufton',
    title: 'Modesto Account Manager',
    phone: '(209) 601-7362',
    email: 'jasond@barneswelding.com',
    location: 'Modesto',
  },
  {
    img: ScottC,
    name: 'Scott Campbell',
    title: 'Modesto Account Manager',
    phone: '(209) 765-6240',
    email: 'scottc@barneswelding.com',
    location: 'Modesto',
  },
  {
    img: KenH,
    name: 'Ken Howe',
    title: 'Stockton Account Manager',
    phone: '(209) 602-9395',
    email: 'kenh@barneswelding.com',
    location: 'Stockton',
  },
  {
    img: PeteE,
    name: 'Pete Elizalde',
    title: 'Salinas Account Manager',
    phone: '(831) 737-0379',
    email: 'petee@barneswelding.com',
    location: 'Salinas',
  },
  {
    img: JeffS,
    name: 'Jeff Schwitzer',
    title: 'Salinas Account Manager',
    phone: '(831) 455-6431',
    email: 'jeffs@barneswelding.com',
    location: 'Salinas',
  },
]

export default EmployeeDirectoryData
